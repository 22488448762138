import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const RenderForm = ({ type }) => {
  switch (type) {
    case 'delegate':
      return <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeWS266eHyFh2xBt06Milglj6qLYTnWr71JMSGySdbu41QGig/viewform?embedded=true" width="640" height="3151" frameBorder={0} marginHeight={0} marginWidth="0">Yükleniyor…</iframe>
    case 'admin':
      return <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfMEzSR0fUjCTnxF2lc6Lw4DD734w3_jNzil2fYHqfjQbdnNw/viewform?embedded=true" width="640" height="1919" frameBorder="0" marginHeight="0" marginWidth="0">Yükleniyor…</iframe>
    case 'chair':
      return <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSehqAt0_OZroeW6vQLF3gFPC2caVph2BlIEDhF44S_9819MYg/viewform?embedded=true" width="640" height="3690" frameBorder="0" marginHeight="0" marginWidth="0">Yükleniyor…</iframe>
    case 'press':
      return <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd5OMwntJ3YM2UWyq0hmqqwXG1g-uYhFE_ldVVnkMkVIld5aQ/viewform?embedded=true" width="640" height="1549" frameBorder="0" marginHeight="0" marginWidth="0">Yükleniyor…</iframe>
    default:
      return <h1>error</h1>
  }
}

const ApplyReg = ({ type }) => {
  const [applyType, setApplyType] = useState(null)
  useEffect(() => {
    setApplyType(type)
  }, [])
  return (
    <>
      <RenderForm type={applyType} />
    </>
  )
}

ApplyReg.propTypes = {
  type: PropTypes.string
}

export default ApplyReg
