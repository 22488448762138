import './App.css'
import Navbar from './Navbar'
import { React } from 'react'
/* eslint-disable react/no-unescaped-entities */ // TODO: upgrade to latest eslint tooling

import FlipCountdown from '@rumess/react-flip-countdown';

const MainPage = () => {
  return (
    <div className="App">
      <Navbar />
      <h1>KALGÇ&lsquo;24 3-4-5 Mayıs 2024 tarinde sizlerle olacak!</h1>
      <FlipCountdown
        size="medium"
        hideYear
        hideMonth
        theme="dark"
        dayTitle='Gün'
        hourTitle='Saat'
        minuteTitle='Dakika'
        secondTitle='Saniye'
        endAtZero
        endAt={'2024-05-03 09:00:00'} // Date/Time
      />
      <footer style={{ bottom: 0, left: 0, marginTop: '2.5rem', width: '100%', height: '2.5rem', position: 'fixed', backgroundColor: '#22223a', padding: 5 }}>
        <p>Made with ❤️ by <a href="https://github.com/efe3535" className="github">Ahmet Efe Akyazı</a></p>
      </footer>
    </div>
  )
}

export default MainPage
