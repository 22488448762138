import './Navbar.css'
import { React } from 'react'

const Navbar = () => {
  return (
    <nav className='menubar'>
      <div className="logo">
        <img className="logo-image" src="./logo.png" style={{ width: 100 }} alt="logo" />
        <hr className="line" style={{ width: 1, borderRadius: 32, border: 'none', display: 'flex', height: '80%', flex: 1, backgroundColor: 'white' }} />
        <h1 className='header'>KALGÇ&lsquo;24</h1>
      </div>

      <div className='buttons'>
        <a className='return' href="/">Ana sayfa</a>
      </div>
    </nav>
  )
}

export default Navbar
